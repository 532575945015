<template>
  <div class="clinician-programs-add">
    <h3>{{ $t('dischargeFromProgram') }}</h3>
    <p class="title">
      {{ patient.name }}
    </p>
    <form @submit.prevent="submit">
      <div class="row">
        <div class="col-5">
          <div class="form-group">
            <label>{{ $t('episodeId') }}</label>
            <p class="form-value">
              {{ patient.episode_id ? patient.episode_id : '-' }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="programName">{{ $t('programName') }}</label>
        <p class="form-value">
          {{ patient.program_name }}
        </p>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>{{ $t('dischargeDate') }}</label>
            <datepicker
              v-model="form.discharge_date"
              class="form-control"
              format="MMMM dd, yyyy"
              :disabled-dates="disabledDates"
            />
            <input
              v-model="form.discharge_date"
              v-validate="'required'"
              type="hidden"
              name="dischargeDate"
              :data-vv-as="$t('dischargeDate')"
            >
            <span
              v-show="errors.has('dischargeDate')"
              class="error"
            >{{ errors.first('dischargeDate') }}</span>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>{{ $t('admissionDate') }}</label>
            <p class="form-value">
              {{ patient.admission_date }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>{{ $t('dispositionStatus') }}</label>
            <select
              v-model="form.disposition_status"
              class="form-control"
            >
              <option
                v-for="item in dispositionStatus"
                :key="item.key"
                :value="item.key"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ $t('dischargeMeasuresTitle') }}</label>
        <p class="subtitle">
          {{ $t('dischargeMeasuresMessage') }}
        </p>
      </div>
      <div class="patient-add-measure">
        <div class="measures-assigned">
          <div
            v-for="schedule in measuresEnqueued"
            :key="schedule.id"
            class="measure"
          >
            {{ schedule.text }}
            <span
              class="delete"
              @click="remove(schedule)"
            >{{ $t('remove') }}</span>
          </div>
        </div>

        <div class="form-group">
          <multiselect
            :open-direction="'bottom'"
            :options="availableMeasuresList"
            :show-labels="false"
            :hide-selected="true"
            :placeholder="$t('select')"
            :internal-search="false"
            :preserve-search="currentBrowser.isExplorer"
            :clear-on-select="true"
            label="text"
            track-by="id"
            @select="assignMeasureToSchedule"
            @search-change="searchMeasures"
          >
            <template slot="caret">
              <i class="fal fa-search" />
            </template>
            <template slot="noResult">
              {{ $t('noResultsFound') }}
            </template>
          </multiselect>
          <span
            v-show="errors.has('measures')"
            class="error"
          >{{ errors.first('measures') }}</span>
        </div>
      </div>

      <div class="buttons">
        <div>
          <button
            :disabled="buttonsDisabled"
            type="button"
            class="btn btn-outline-secondary"
            @click="cancel"
          >
            {{ $t('cancel') }}
          </button>
          <button
            :disabled="buttonsDisabled"
            type="submit"
            class="btn btn-secondary"
          >
            {{ $t('confirm') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import Datepicker from 'vuejs-datepicker'
import DispositionStatusData from '@/data/disposition-status-data'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapMutations } from 'vuex'
import { PatientHelper } from '@/mixins/PatientHelper'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'
import { ACTIONS, MUTATIONS } from '@/store/modules/programList/constants'

export default {
  name: 'ClinicianDischargeFromProgram',
  components: {
    Datepicker,
    Multiselect
  },
  mixins: [Helpers, DateTimeHelper, PatientHelper],
  props: [],
  data () {
    return {
      patient: {
        name: '',
        episode_id: '',
        program_name: '',
        admission_date: ''
      },
      form: {
        discharge_date: moment().format(),
        disposition_status: 2
      },
      disabledDates: {
        from: moment().toDate()
      },
      dispositionStatus: DispositionStatusData,
      selectedMeasure: {},
      availableMeasuresList: [],
      availableMeasures: [],
      measuresEnqueued: [],
      buttonsDisabled: true,
      loading: false,
      loaded: false,
      routeFrom: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.routeFrom = from
    })
  },
  computed: {
    ...mapGetters({
      accessControl: 'getSinglePatient',
      accessControlProgram: 'getSinglePatientProgram',
      currentBrowser: 'getCurrentBrowser',
      schedulingOptions: 'getSchedulingOptions',
      homeTab: 'getHomeTab'
    }),
    saveText () {
      return this.$route.name === 'ClinicianAddMeasureToPatient' ? this.$t('done') : this.$t('done')
    },
    computedForm () {
      return {
        form: Object.assign({}, this.form),
        measures: Object.assign([], this.measuresEnqueued)
      }
    }
  },
  watch: {
    computedForm: {
      handler (n, o) {
        if (this.loaded) {
          const formDiff = Object.keys(this.form).some(field => n.form[field] !== o.form[field])
          const measuresDiff = !(n.measures.length === o.measures.length && n.measures.every((item, index) => item.id === o.measures[index].id))
          this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', formDiff || measuresDiff)
        }
      },
      deep: true
    }
  },
  created () {
    if (!this.loading) {
      this.loading = true
      const loader = this.$loading.show()
      const routeParams = this.$route.params
      const params = {
        accessControlProgramId: this.$getDecodedId(routeParams.accessControlProgramId),
        patientId: this.$getDecodedId(routeParams.patientId),
        assignmentId: this.$getDecodedId(routeParams.assignmentId)
      }

      const patientProgramPromise = this.$store.dispatch('GET_SINGLE_PATIENT_PROGRAM', params)
      const patientPromise = this.$store.dispatch('GET_PATIENT', params.patientId)
      const schedulingPromise = this.$store.dispatch('GET_SCHEDULING_OPTIONS', { ccauId: params.assignmentId })

      Promise.all([patientProgramPromise, patientPromise, schedulingPromise]).then(() => {
        this.availableMeasures = this.schedulingOptions.available_measures
        this.availableMeasuresList = this.availableMeasures
        this.patient = {
          name: this.accessControl.first_name,
          episode_id: this.accessControlProgram.episode_id,
          program_name: this.accessControlProgram.program.full_name,
          admission_date: this.$toLocal(this.accessControlProgram.admission_date, 'MM/DD/Y')
        }

        this.addDischargeMeasures()
        this.addMeasuresAssigned()

        this.buttonsDisabled = false
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        this.loading = false
        this.loaded = true
        loader.hide()
      })
    }
  },
  destroyed () {
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    ...mapMutations({
      SET_HOME_TABS: 'user/SET_HOME_TABS'
    }),
    addMeasuresAssigned () {
      // @deprecated, measures that are not part of Previously set Discharge measures are not
      // Added to be shown as part of the Discharge measures
      // Uncomment if necessary.
      // for (const item of this.schedulingOptions.measures_assigned) {
      //   if (Number(item.access_control_program_id) === Number(this.accessControlProgram.id)) {
      //     this.measuresEnqueued.push({
      //       text: item.measure_name,
      //       value: item.measure_id,
      //       id: item.measure_id
      //     })
      //   }
      // }
    },
    addDischargeMeasures () {
      const respondentTypesSettingName = this.patient.age >= 18 ? 'measure_respondent_type_adult' : 'measure_respondent_type_child'
      const availableRespondentsTypes = this.settings[respondentTypesSettingName]
      const dischargeMeasures = this.accessControlProgram.program.measures.filter(measure => {
        return parseInt(measure.day_of_week) === 101 && availableRespondentsTypes.includes(measure.measure_storage.respondent)
      })

      for (let i = 0, len = dischargeMeasures.length; i < len; i++) {
        const item = dischargeMeasures[i]
        this.measuresEnqueued.push({
          text: `${item.measure_storage.abbreviation} ${item.measure_storage.name}`,
          value: item.measure_id,
          id: item.measure_id
        })
      }
    },
    submit () {
      this.buttonsDisabled = true
      const measures = []
      for (const measure of this.measuresEnqueued) {
        measures.push(measure.id)
      }
      this.promptConfirmation().then(() => {
        const loader = this.$loading.show()
        const routeParams = this.$route.params
        const params = {
          access_control_program_id: this.$getDecodedId(routeParams.accessControlProgramId),
          patient_id: this.$getDecodedId(routeParams.patientId),
          date: moment(this.form.discharge_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
          measures: measures,
          disposition_status: this.form.disposition_status,
          assignment_id: this.$getDecodedId(routeParams.assignmentId)
        }

        this.$store.dispatch('DISCHARGE_FROM_PROGRAM', params).then((responseData) => {
          const discharged = responseData.result
          const message = discharged ? 'dischargeSuccessMessage' : 'dischargePendingMessage'
          const action = discharged ? 'success' : 'warning'
          this.$toast[action]({ message: this.$t(message) })
          // Would be ideal to do this generically OWL-6556 - Supervisor does not have access to patient data after discharge or team change.
          if (!responseData.hasOwnProperty('cancelActions') || !responseData.cancelActions) { // eslint-disable-line no-prototype-builtins
            this.getDataAfterDischarge(!!discharged)
          } else {
            this.$router.push({ name: 'ClinicianHomepage' })
          }
          this.$emit('patient-discharged')
        }).catch(() => {
          this.buttonsDisabled = false
        }).finally(() => {
          loader.hide()
        })
      }).catch(() => {
        this.buttonsDisabled = false
      })
    },
    getDataAfterDischarge (dischargeCompleted) {
      this.getPatientsByProgramsData(dischargeCompleted).then(() => {
        this.$refreshActiveAssignmentData()
        componentRefreshSingleton.refreshComponentViewByName('ClinicianPatientHeader')
        if (this.routeFrom.name === 'ClinicianHomepage') { // and OR condition to check whether clinician-only user is still associated with this patient
          this.$router.push({ name: this.routeFrom.name, query: { homeTab: this.homeTab, justRemoved: 1 } })
        } else if (this.routeFrom.name === 'ClinicianPatientPrograms') {
          this.$router.push({ name: 'ClinicianPatientPrograms' })
        } else {
          this.$router.go(-1)
        }
      })
    },
    getPatientsByProgramsData (dischargeCompleted) {
      const dischargeSection = this.$route.params.dischargingFromSection
      return new Promise((resolve) => {
        if (dischargeSection !== 1) {
          return resolve(true)
        }

        dischargeCompleted ? this.removePatientFromProgramList() : this.updatePatientInProgramList()
        resolve(true)
      })
    },
    removePatientFromProgramList () {
      this.$store.commit(MUTATIONS.removePatientFromProgram, {
        access_control_id: this.accessControlProgram.access_control_id,
        program_id: this.accessControlProgram.program_id
      })
    },
    updatePatientInProgramList () {
      this.$store.dispatch(ACTIONS.getUpdatedPatientData, {
        patientId: this.accessControlProgram.access_control_id,
        programId: this.accessControlProgram.program_id,
        justAdded: false
      }).then((patientData) => {
        this.$store.commit(MUTATIONS.updatePatientInProgram, patientData)
      })
    },
    promptConfirmation () {
      return new Promise((resolve, reject) => {
        const promptOptions = {
          message: this.$t('dischargeConfirmMessage'),
          title: this.$t('dischargeConfirmTitle'),
          cancelButton: this.$t('noResume'),
          okButton: this.$t('yesDischarge')
        }
        this.$promptBeforeAction(promptOptions, () => resolve(), () => reject(new Error()))
      })
    },
    assignMeasureToSchedule (measure) {
      const measureSelected = this.availableMeasuresList.find((item) => item.id === measure.value)
      if (!measureSelected) {
        return
      }
      this.measuresEnqueued.push(measureSelected)
    },
    remove (measure) {
      this.measuresEnqueued = this.measuresEnqueued.filter((item) => item.id !== measure.id)
    },
    searchMeasures (query) {
      const q = query.toLowerCase()
      this.availableMeasuresList = this.availableMeasures.filter(obj => {
        return obj.text.toLowerCase().indexOf(q) > -1 || obj.conditions.toLowerCase().indexOf(q) > -1
      })
    },
    cancel () {
      this.$router.push({ name: 'ClinicianHomepage' })
    }
  }
}
</script>
