const dispositionStatus = [
  { key: 1, label: 'Admitted' },
  { key: 2, label: 'Completed treatment' },
  { key: 3, label: 'Dropped Out' },
  { key: 4, label: 'Aged Out' },
  { key: 5, label: 'Transferred' },
  { key: 6, label: 'Discharged due to inactivity' }
]

export default dispositionStatus
